<template>
    <div id="moat-comps-container" :style="dashboardChartStyle">
        <div v-show="displayDashboard" class="moat-comps-container">
            <div class="moat-comps-header">
                To discover Moat Comps, we match a unique, digital customer profile with those of our benchmark companies.
            </div>
            <div class="moat-comps-body" v-show="this.noData == false && this.notEnoughData == false && this.displayDashboard == true">
              <div class="moat-comps-by">
                  <div class="moat-comps-sub-header">
                      <div>By Company</div>
                      <div style="padding-right: 13px;">Fit</div>
                  </div>
                  <div v-for="(moatComp, idxMoatComp) in rankingsByCompany" v-bind:key="idxMoatComp" class="moat-comps-results">
                      <div class="ranking col-2"><div>{{ moatComp.rank }}</div></div>
                      <div class="company-image col-4"><div :class="moatComp.imageFileName"/></div>
                      <div class="company-name col-4">{{ moatComp.companyName }}</div>
                      <div class="company-percentage col-2">{{ moatComp.percentFitDisplay }}%</div>
                  </div>
              </div>
              <div class="moat-comps-by" style="padding-top: 5px;">
                  <div class="moat-comps-sub-header">
                      <div>By Framework</div>
                      <div style="padding-right: 13px;">Fit</div>
                  </div>
                  <div v-for="(moatComp, idxMoatComp) in rankingsByFramework" v-bind:key="idxMoatComp" class="moat-comps-results">
                      <div class="ranking col-2"><div>{{ moatComp.rank }}</div></div>
                      <div class="company-image col-4"><div :class="moatComp.imageFileName"/></div>
                      <div class="company-name col-4">{{ moatComp.companyName }}</div>
                      <div class="company-percentage col-2">{{ moatComp.percentFitDisplay }}%</div>
                  </div>
              </div>
            </div>
            <div class="moat-comps-body" style="margin: auto 0px;">
              <div v-if="this.noData == true" class="no-data-for-chart-container contribution">
                  <div v-show="this.noData == true" class="no-data-for-chart mx-auto">
                    <div class="pb-2">Not enough data in your selection</div>
                    <div class="pb-2">exists to build a digital profile.</div>
                    <div class="pb-2">Try selecting a different period, organization or group.</div>
                  </div>
              </div>
              <div v-if="this.notEnoughData == true" class="no-data-for-chart-container contribution">
                  <div v-show="this.notEnoughData == true" class="no-data-for-chart mx-auto">
                    Not enough data in your selection  <br /> 
                    exists to build a digital profile.  <br />
                    Try selecting a different period, organization or group.
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  name: "MoatCompsBody",
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    rankingsByCompany: {
      type: Array,
      default: function() {
        return [];
      }    
    },
    rankingsByFramework: {
      type: Array,
      default: function() {
        return [];
      }   
    },
    noData: {
      type: Boolean,
      default: false
    },
    notEnoughData: {
      type: Boolean,
      default: false
    },
    displayDashboard: {
      type: Boolean,
      default: false
    },
    isModal:{
        type: Boolean,
        default: false
    }
  },
  computed: {
    dashboardChartStyle: function () {
      if (this.isModal) {
        if (isMobile) {
          return "width: 200px;"

        } else {
          return "width: 500px;"
        }
      }
      return "height: 100%;";
    },
  },
  mounted() {},
  created() {},
  methods: {},
  watch: {}
};
</script>
