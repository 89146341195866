<template>
    <div class="e-chart-buttons mt-2 mb-2">
      <div class="single-wave-charts">
        <div class="d-flex">
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('bar-horizontal',1)"><img :class="getClass(1)" src="../../assets/img/chart-buttons/Chart-Bar-Horizontal-Color.png" /></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('bar-vertical',2)"><img :class="getClass(2)" src="../../assets/img/chart-buttons/Chart-Bar-Color.png" /></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('pie',3)"><img :class="getClass(3)" src="../../assets/img/chart-buttons/Chart-Pie-Color.png" /></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('doughnut',4)"><img :class="getClass(4)" src="../../assets/img/chart-buttons/Chart-Donut-Color.png" /></a></div>
        </div> 
      </div>
      <div class="multi-wave-charts">
        <div class="d-flex">
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('stacked-bar-vertical',5)"><img :class="getClass(5)" src="../../assets/img/chart-buttons/Chart-Bar-Stack-Color.png" /></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('stacked-bar-horizontal',6)"><img :class="getClass(6)" src="../../assets/img/chart-buttons/Chart-Bar-Stack-Horizontal-Color.png" /></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('stacked-area',7)"><img :class="getClass(7)" src="../../assets/img/chart-buttons/Chart-Area-Color.png"/></a></div>
          <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('line',8)"><img :class="getClass(8)" src="../../assets/img/chart-buttons/Chart-Line-Color.png" /></a></div>
        </div>        
      </div>
      <div class="mt-4">
            <div class="form-group">
              <label for="recalculate" class="mr-1">Calculation Type <modal-link modal-id="Calculation Type" :content-list="contentList"></modal-link></label>
              <select
                  id="recalculate"
                  name="recalculate"
                  class="custom-select required shadow"
                  @change="changeRecalculation($event)"
                  v-model="recalculate"
              >
                  <option v-for="(option, idxRecalculateOptions) in recalculateOptions" v-bind:value="option.recalculate" v-bind:key="idxRecalculateOptions">
                      {{ option.label }}
                  </option>
              </select>
            </div>
            <div class="form-group">
                <label for="displayOrder" class="mr-1">Sort Type <modal-link modal-id="Sort Type" :content-list="contentList"></modal-link></label>
                <select
                    id="displayOrder"
                    name="displayOrder"
                    class="custom-select required shadow"
                    @change="changeDisplayOrder($event)"
                    v-model="displayOrder"
                >
                    <option v-for="(option, idxDisplayOrderOptions) in displayOrderOptions" v-bind:value="option.order" v-bind:key="idxDisplayOrderOptions">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <show-label :input-show-labels="showLabels" @set-show-labels="changeShowLabels" :content-list="contentList"></show-label>
            <show-legend :input-show-legend="showLegend" @set-show-legend="changeShowLegend"></show-legend>          
            <div class="form-group color-pallets">
                <label for="colorPaletteId" class="mr-1">Color Palette <modal-link modal-id="Color Palette" :content-list="contentList"></modal-link></label>
                <v-select
                  id="colorPaletteId"
                  name="colorPaletteId"
                  :options="colorPaletteOptions"
                  :reduce="color_palette_option => color_palette_option.index"
                  label="label"
                  :clearable="false"
                  v-model="colorPaletteId"
                >
                <template v-slot:option="option">
                  <div :class="option.type">&nbsp;</div>
                </template>
              </v-select>                
            </div>
        </div>

    </div>
</template>

<script>
import AdminService from "../../services/admin.service";
import ModalLink from "../../components/ModalLink.vue";
import EChartsButtonsMixins from '../../mixins/EChartsButtonsMixins.vue';
import ShowLegend from './ShowLegend.vue';
import ShowLabel from './ShowLabel.vue';

export default {
  name: "ResponseRateChartButtons",
  mixins: [EChartsButtonsMixins],
  components: {ModalLink,ShowLegend,ShowLabel},
  data() {
    return {
      recalculate: true,
      skipValidation: false,
      recalculateOptions: [
            { label: "Add up to 100%", recalculate: true },
            { label: "Display as actual %", recalculate: false },
      ],
      displayOrder: "question",
      displayOrderOptions:[
          { label: "Question Order", order: "question" },
          { label: "High to Low", order: "high-to-low" },
          { label: "Low to High", order: "low-to-high" }
      ],
    };
  },
  props: {
    inputNumberOfWaves: {
        type: Number,
        default: 0
    },
    inputRecalculate: {
        type: Boolean,
        default: true
    } ,
    inputDisplayOrder: {
        type: String,
        default: "question"
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    loadChart: function(skipValidation,eChartType){ 
      this.skipValidation = skipValidation;
      let finalEChartType = this.inputEChartType
      if (typeof eChartType !== 'undefined'){
        finalEChartType = eChartType;
      }
      this.setChart(finalEChartType, this.getChartIndex(this.inputEChartType,this.inputRecalculate), this.inputRecalculate);      
    },  
    changeDisplayOrder: function(event) {
        this.$emit("set-display-order", this.displayOrder);
    },
    changeRecalculation: function(event) {
        this.$emit("set-recalculate", this.recalculate);
    },
    getClass:function(index){
        return this.chartSelected == index ? "selected" : "";
    },
    verifyChartCriteria: function(eChartType){
      let verified = true;
      if (this.skipValidation == true){
        return verified;
      }
      if (this.inputNumberOfWaves > 4 && (eChartType == "pie" || eChartType == "doughnut")){
        let errorMessage = "Only 4 waves are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: errorMessage });
        verified = false;
      } else if (this.inputNumberOfWaves <= 1 && eChartType == "stacked-area"){
        let errorMessage = "Displaying this chart as a single wave is not possible. Please select additional waves to display this chart.";
        AdminService.displayErrorMessage({ text: errorMessage });
        verified = false;
      }      
      return verified;
    },
  },
  watch: {
    inputRecalculate: function(){
        this.recalculate = this.inputRecalculate;
    },
    inputDisplayOrder: function(){
        this.displayOrder = this.inputDisplayOrder;
    },
  },
};
</script>
