<template>
    <div class="col-12 no-gutters container">
<!--
        <div class="e-chart-container">
            <div class="title-and-chart">  
-->            
                <div :id="eChartId" :class="inputClassName">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
<!--
                
            </div>
        </div>
-->            
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import ProfileService from "../../services/echarts/profile.service";
import DashboardService from "../../services/dashboard.service";
import SymbolService from "../../services/symbol.service";
import MoneyballStickService from "../../services/echarts/moneyball.stick.service";
import NeedsService from "../../services/needs.service";
import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "MoneyballStickChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        emotionalNeedsNameOrder: [],
        driverNames: [],
        displayPricingPower: false,
        pricingPowerSeriesNumber: 0,
        addWrapper: false,
        maximumAxisLabelWidth: 0,
        totalCharacters: 0,
        matchType: "",



        maxPricingPower: 5,
        maxAverage: 2,
        maxYAxis: 10,

        baseSeries: MoneyballStickService.getBaseSeries(),      
        baseYSeries: MoneyballStickService.getBaseYSeries(),

        legendData: [
            "Willingness to Pay",
            "Importance Avg",
            "Satisfaction Gap Avg",
            "Emotions Gap Avg",
        ],  
        xAxisData: [],
        wtpData: [],
        importanceAvgData: [],
        satisfactionGapAvgData: [],
        emotionsGapAvgData: [],

        minChartHeight: 0,
        isDashboardCard: true,
        options: {}
    };
  },
  props: {
    inputDownloadFileName: {
      type: String,
      default: "Profile"
    },
    inputClassName: {
      type: String,
      default: "moneyball-stick-dashboard-chart e-chart"
    },
  },
  computed: {
    dashboardChartStyle: function() {
      return "height: " + this.minChartHeight + "px";
    },

  },
  mounted() {},
  created() {},
  methods: {
    getChartOptions: function(){
        return this.options;           
    },
    setDataForMoneyballStick: function(moneyballStickChartData){
        this.maxPricingPower = moneyballStickChartData.maxWTP;
        this.maxAverage = moneyballStickChartData.maxYAxis;
        this.wtpData = moneyballStickChartData.wtpData;
        this.importanceAvgData = moneyballStickChartData.importanceAvgData;  
        this.satisfactionGapAvgData = moneyballStickChartData.satisfactionGapAvgData;  
        this.emotionsGapAvgData = moneyballStickChartData.emotionsGapAvgData;
        this.xAxisData = moneyballStickChartData.xAxisData;

        let grid = this.isDashboardCard ? MoneyballStickService.getGrid(200) : ProfileService.getGridForSingleChart();
        let yAxis = MoneyballStickService.getYAxis(this.maxYAxis,this.maxPricingPower,this.isDashboardCard);
        let rotate = this.isDashboardCard ? 25 : 25; 
        let xAxis = MoneyballStickService.getXAxis(this.xAxisData,rotate);
        let toolTip = MoneyballStickService.getTooltip() ;
        let series = MoneyballStickService.getSeries(this.wtpData,this.importanceAvgData,this.satisfactionGapAvgData,this.emotionsGapAvgData, this.xAxisData);
        let color = MoneyballStickService.getColor();
        
        ScatterService.setYAxis(yAxis); 
        ScatterService.setXAxis(xAxis);
        ScatterService.setGrid(grid);
        ScatterService.setSeries(series);
        if (this.isDashboardCard == false){
          ScatterService.setLegendData(this.legendData);
        }
        this.options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName,this.inputDownloadFileName);
        this.options.series = series;
        this.options.tooltip = toolTip;
        this.options.tooltip.formatter =  MoneyballStickService.toolTipForMoneyballStick;       
        this.options.color = color;

        if (this.isDashboardCard == false){
          this.options.grid[0].left = 90;
          this.options.legend.top = 80;

        } else {
          this.options.legend = MoneyballStickService.getLegend(this.legendData);
          this.options.toolbox = {}     
        }
    },
    processChart: function(runChart = false,moneyballStickChartData,isDashboard = false) {
        if (runChart == true) {
          this.isDashboardCard = isDashboard;
          if (this.isDashboardCard== true){
            this.minChartHeight = 250;
          } else {
            this.minChartHeight = 600;
          }
            this.setDataForMoneyballStick(moneyballStickChartData);
            this.initChart(moneyballStickChartData);
            if (this.isDashboardCard== false){
              this.$emit('save-criteria',true);
              this.$emit('save-table');
            }                
        }
    },    
  },
  watch: {},
};
</script>
