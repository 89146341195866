<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <respondent-menu></respondent-menu>
    <div v-if="!permissionToView" class="no-permission text-center">You do not have permission to view this page</div>   
    <div v-else class="container metrics">
      <div class="button-container">
        <div class="button-left">
          <div class="pr-2">
            <a href="#" @click="goToAddMetric()">
              <button type="button" class="btn btn-success" id="AddNewMetricButton">
                Add Metric
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </span>
              </button>
            </a>
          </div>
          <div class="pr-2">
            <a href="#" @click="editMetricGroup(this.table.rowSelectedId)">
              <button type="button" class="btn btn-primary" id="EditClientButton" :disabled="!table.rowSelected">
                Edit Metric
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
              </button>
            </a>
          </div>
          <div class="pr-2">
            <a class="pr-2" href="#" @click="displayDeleteModal">
              <button type="button" class="btn btn-primary" id="DeleteMetricButton" :disabled="!table.rowSelected">
                Delete Metric
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="displayImportMetricModal">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Metrics <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a href="/api/v1/client/download-metric-import-template" @click.prevent="logButtonClickEvent('Download Metric Import Template');downloadItem()" id="DownloadMetricImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download Metric Import Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Metric Group Id</th>
                <th>Name</th>
                <th>Metric Type</th>
                <th>Metric Display Type</th>
                <th>Growth Rate</th>
                <th>Client Id</th>
                <th>Client Competitor Id</th>
                <th>Company Name</th>
                <th>Survey Name</th>
                <th>Active</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
    <import-metrics :initial-metric-name="currentMetricGroupName" :initial-metric-group="currentMetricGroup" :initial-mode="mode" :row-selected="table.rowSelected" reload="reloadTable"/>

  </div>
</template>

<script>
import CompanyMixins from "../../mixins/CompanyMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import RespondentMenu from "../../views/menu/RespondentMenu.vue";
import ImportMetrics from "../../modals/ImportMetrics.vue";

import AdminService from "../../services/admin.service";
import MetricsService from "../../services/metrics.service";
import GoogleService from "../../services/google.service";
import AlertService from "../../services/alert.service";
import { useRouter } from 'vue-router';

export default {
  name: "MetricGroupList",
  mixins: [CompanyMixins, DataTableMixins],
  components: { RespondentMenu, ImportMetrics },
  props: {},
  setup() {
    const router = useRouter();

    const editMetricGroup = (id) => {
      router.push({ 
        name: 'edit-metric-group', 
        params: { initialMode: "edit-metric-group",initialMetricGroupId: id } 
      });          
    };
    return {
      editMetricGroup
    };
  },
  data() {
    return {
      pageNumber: 6,
      title: "Metrics",
      table: {
        id: "metric-list-table",
        columns: []
      },
      mode: "new",
      createNewMetricGroup: false,
      importMetricImportFile: "",
      metricGroup: MetricsService.getDefaultMetricGroup()
    };
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/client/download-metric-import-template";
    },
    calculate_growth_rate_options: function() {
      return AdminService.getCalculateGrowthRateOptions();
    },
    currentMetricGroup: function() {
      if (!this.table.rowSelected) {
        return MetricsService.getDefaultMetricGroup();
      } else {
        let selectedMetricGroup = this.table.data.find(x => x.metricGroupId == this.table.rowSelectedId);
        if (typeof selectedMetricGroup != "undefined") {
          return selectedMetricGroup;
        } else {
          return MetricsService.getDefaultMetricGroup()
        }
      }
    },
    currentMetricGroupName: function() {
      if (!this.table.rowSelected) {
        return "";
      } else {
        let selectedMetricGroup = this.table.data.find(x => x.metricGroupId == this.table.rowSelectedId);
        if (typeof selectedMetricGroup != "undefined") {
          return selectedMetricGroup.name;
        } else {
          return "";
        }
      }
    }
  },
  methods: {
    reloadTable(){
      this.table.dataTable.draw(true);
    },
    goToAddMetric(){
      this.logButtonClickEvent('Add');
      this.$router.push({ name: 'add-metric-group', params: { initialMode: 'add-metric-group' } });
    },
    goToEditMetric(){
      this.logButtonClickEvent('Edit');
      this.$router.push({ name: 'add-metric-group', params: { initialMode: 'edit-metric-group', initialMetricGroupId: this.table.rowSelectedId } });
    },
    displayDeleteModal(){
      let title = 'Delete Confirmation Metric';
      let content = 'Are you sure you want delete the metric group ?';
      let parameter = this.table.rowSelectedId;
      let callback = this.deleteMetricGroup;
      AlertService.showConfirmationMessageWithParameter(callback,parameter,title,content);            
      this.logButtonClickEvent('Delete');
    },
    logButtonClickEvent(action){
      GoogleService.logClickEvent(this.clientName, "Metrics - " + action,  0, this);
    },
    displayImportMetricModal: function() {
      if (this.table.rowSelected) {
        this.mode = "existing";
      }
      this.logButtonClickEvent('Import Metrics');
      this.$modal.show("import-metric-file");
    },
    deleteMetricGroup: function() {
      this.$root.processServerRequest("Deleting metric group");
      MetricsService.deleteMetricGroup(this.table.rowSelectedId, this.deleteMetricGroupCallback, this);
    },
    deleteMetricGroupCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$router.go();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: function() {
      if (!this.isW5OUser){
        this.table.columns = MetricsService.getTableColumnData();
        this.getMetrics();
      }
      this.$root.setDisableSurveyDropdown(true);
    },
    async getMetrics() {
      this.$root.processServerRequest("Loading company metrics");
      MetricsService.getMetricList(this.getTableDataCallback, this);
    },
    getDownloadFilename() {
      return "Metric Import Template";
    },    
  },
  created() {},
  mounted() {},
  beforeRouteLeave: function(to, from, next) {
    this.$root.setDisableSurveyDropdown(false);
    next();
  }
};
</script>
