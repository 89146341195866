<template>
  <div>
    <div v-if="displayCards" class="dashboard-card-container">
        <dashboard-respondents v-if="saveClientChartObject.displayType == 'respondent-map'" 
          ref="respondents" dashboard-id="respondents" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'respondents-map',false)"
          />
        <dashboard-expectations-map v-if="saveClientChartObject.displayType == 'expectations-map'" 
          ref="expectationsMap" dashboard-id="expectationsMap" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'expectations-map',false)"
        />
        <dashboard-performance-trends  v-if="saveClientChartObject.displayType == 'performance-trends'" 
          ref="performanceTrends" dashboard-id="performanceTrends" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-start-time-period-id="getCriteriaParameterForDashboardCard(saveClientChartObject,'startTimePeriodId')"
          :entry-client-chart="saveClientChartObject"
          :entry-survey-code="getCriteriaParameterForDashboardCard(saveClientChartObject,'surveyCode')" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'performance-trends',false)"
        />
        <dashboard-moat-comps ref="moatComps" dashboard-id="moatComps" 
          v-if="saveClientChartObject.displayType == 'dashboard-moat-comps'" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-comps',false)"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="getCriteriaParameterForDashboardCard(saveClientChartObject,'surveyCode')" 
          :entry-client-chart="saveClientChartObject"
        />
        <dashboard-moneyball-stick ref="moneyballStick" 
          dashboard-id="moneyballStick" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moneyball-stick',false)"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :load-competitor-data="true"
          :input-client-charts="clientObject.clientCharts"
          :entry-survey-code="getCriteriaParameterForDashboardCard(saveClientChartObject,'surveyCode')" 
          :entry-client-chart="saveClientChartObject"
        />
        <dashboard-rank ref="npsRank" dashboard-id="npsRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-rank',false)"
          rank-type="nps-rank"
          :entry-client-chart="saveClientChartObject"
          :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-nps-rank'"  
        />     
        <dashboard-rank ref="wScoreRank" dashboard-id="wScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'w-score-rank',false)"
          rank-type="w-score-rank"
          :entry-client-chart="saveClientChartObject"
          :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-w-score-rank'"  
        />  
        <dashboard-rank ref="moatScoreRank" dashboard-id="moatScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-score-rank',false)"
          rank-type="moat-score-rank"
          :entry-client-chart="saveClientChartObject"
          :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-moat-score-rank'"  
        />                     
        <dashboard-predictive-analytics-contributions ref="predictiveAnalytics" 
            v-if="saveClientChartObject.displayType == 'dashboard-predictive-analytics'"  
            :entry-client-chart="saveClientChartObject"
            dashboard-id="predictiveAnalytics" 
            :input-metric-options="metric_options"
            :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
            :input-is-pricing-power="getCriteriaParameterForDashboardCard(saveClientChartObject,'isPricingPower') == 'true'" 
            :read-only="true"
            :initial-metric-id="String(getCriteriaParameterForDashboardCard(saveClientChartObject,'metricGroupId'))" 
            :entry-survey-code="entrySurveyCode" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'predictive-analytics',false)"/>

        <dashboard-predictive-analytics-contributions ref="metricContributions" 
            v-if="saveClientChartObject.displayType == 'dashboard-metric-contributions'"  
            :entry-client-chart="saveClientChartObject"
            dashboard-id="metricContributions" 
            :input-metric-options="metric_options"
            :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
            :input-is-pricing-power="getCriteriaParameterForDashboardCard(saveClientChartObject,'isPricingPower') == 'true'" 
            :read-only="true"
            :initial-metric-id="String(getCriteriaParameterForDashboardCard(saveClientChartObject,'metricGroupId'))" 
            :entry-survey-code="entrySurveyCode" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'metric-contributions',false)"/>

        <dashboard-competitive-trends v-if="saveClientChartObject.displayType == 'dashboard-competitive-trends'"  
          ref="competitiveTrends" dashboard-id="competitiveTrends" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-start-time-period-id="getCriteriaParameterForDashboardCard(saveClientChartObject,'startTimePeriodId')"
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-trends')"
        />
        <dashboard-competitive-rank  v-if="saveClientChartObject.displayType == 'competitive-rank'" 
          ref="competitiveRank" dashboard-id="competitiveRank" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-rank',false)"
        />
        <dashboard-nps-trends v-if="saveClientChartObject.displayType == 'nps-trends'"  
        ref="npsTrends" dashboard-id="npsTrends" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-trends',false)"
        />
        <dashboard-needs-profile  v-if="saveClientChartObject.displayType == 'needs-profile'" 
          ref="needsProfile" dashboard-id="needsProfile" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-start-time-period-id="getCriteriaParameterForDashboardCard(saveClientChartObject,'startTimePeriodId')"
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'needs-profile',false)"
        />
        <dashboard-growth-sequence v-if="saveClientChartObject.displayType == 'growth-sequence'"  
          ref="growthSequence" dashboard-id="growthSequence" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'growth-sequence',false)"
        />
        <dashboard-winners-map v-if="saveClientChartObject.displayType == 'winners-map'"  
          ref="winnersMap" dashboard-id="winnersMap" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'winners-map',false)"
        />
        <dashboard-welcome v-if="saveClientChartObject.displayType == 'welcome'"   
          :entry-client-chart="saveClientChartObject"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'welcome',false)"
        />
    </div>
  </div>
</template>

<script>
import AdminService from "../services/admin.service";
import MetricsService from "../services/metrics.service";
import SurveyService from "../services/survey.service";
import SurveyGroupService from "../services/survey.group.service";
import DashboardService from "../services/dashboard.service";
import DashboardRespondents from "./dashboard/DashboardRespondents.vue";
import DashboardCompetitiveRank from "./dashboard/DashboardCompetitiveRank.vue";
import DashboardCompetitiveTrends from "./dashboard/DashboardCompetitiveTrends.vue";
import DashboardExpectationsMap from "./dashboard/DashboardExpectationsMap.vue";
import DashboardPerformanceTrends from "./dashboard/DashboardPerformanceTrends.vue";
import DashboardPredictiveAnalyticsContributions from "./dashboard/DashboardPredictiveAnalyticsContributions.vue";
import DashboardNpsTrends from "./dashboard/DashboardNpsTrends.vue";
import DashboardNeedsProfile from "./dashboard/DashboardNeedsProfile.vue";
import DashboardGrowthSequence from "./dashboard/DashboardGrowthSequence.vue";
import DashboardWinnersMap from "./dashboard/DashboardWinnersMap.vue";
import DashboardWelcome from "./dashboard/DashboardWelcome.vue";
import DashboardRank from "./dashboard/DashboardRank.vue";
import DashboardMoatComps from "./dashboard/DashboardMoatComps.vue";
import DashboardMoneyballStick from "./dashboard/DashboardMoneyballStick.vue";

export default {
  name: "SavedDashboardCards",
  mixins: [],
  components: {
    DashboardRespondents,
    DashboardCompetitiveRank,
    DashboardCompetitiveTrends,
    DashboardExpectationsMap,
    DashboardPerformanceTrends,
    DashboardPredictiveAnalyticsContributions,
    DashboardNpsTrends,
    DashboardNeedsProfile,
    DashboardGrowthSequence,
    DashboardWinnersMap,
    DashboardWelcome,
    DashboardRank,
    DashboardMoatComps,
    DashboardMoneyballStick
  },
  data() {
    return {
      currentTimePeriod: "",
      client_time_periods: [],
      defaultDashboardValues: {},
      dashboardLayoutCards: [],
      metric_options: [],
      surveyGroupList: [],
      displayCards: false,
      metricId: "",
      entrySurveyCode: "",
      defaultGroup: { dashboardCardId: 0, id: 0, sequenceNumber: 100, dashboardCardTypeCode: "" },
    };
  },
  props: {
    clientObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    saveClientChartObject: {
      type: Object,
      default: function() {
        return {
          clientChartId: 0
        };
      }
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getDashboardLayoutCard: function(layoutCards, code) {
      return DashboardService.getDashboardLayoutCard(layoutCards, code);
    },
    getCriteriaParameterForDashboardCard(saveClientChartObject, parameterName){
      let criteriaArray = saveClientChartObject.criteria.split("&");
      for (let criteriaIndex = 0; criteriaIndex < criteriaArray.length; criteriaIndex++) {
        let criteria = criteriaArray[criteriaIndex];
        let criteriaParameters = criteria.split("=");
        let defaultParameter = criteriaParameters[0];
        let defaultValue = criteriaParameters[1];
        let isMetricId = defaultParameter == parameterName ? true : false;
        if (isMetricId){
          return defaultValue;
        }
      }
      return "";
    },    
    getAllDashboardCardTypesAsCardsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.dashboardLayoutCards = response.data.data;
        let currentSequenceNumber = this.dashboardLayoutCards.length;
        let cardToAdd = JSON.parse(JSON.stringify(this.defaultGroup));
        currentSequenceNumber += 1;
        cardToAdd.sequenceNumber = currentSequenceNumber;
        this.dashboardLayoutCards.push(cardToAdd);
        DashboardService.saveAllDashboardCardTypesAsCards(this.dashboardLayoutCards);
        this.displayCards = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: async function() {
      let clientCode = this.getCriteriaParameterForDashboardCard(this.saveClientChartObject,'clientCode');
      this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
      await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
      let dashboardCardTypes = await DashboardService.getAllDashboardCardTypesAsCards();
      if (dashboardCardTypes.length == 0){
        await DashboardService.getAllDashboardCardTypesAsCardsFromDatabase(this.getAllDashboardCardTypesAsCardsCallback, this);
      } else {
        this.dashboardLayoutCards = JSON.parse(JSON.stringify(dashboardCardTypes));
        this.displayCards = true;
      }      
      await SurveyGroupService.getSurveyGroupsByClientId(clientCode,this.getSurveyGroupsByClientIdCallback, this);

      if (this.$refs.performanceTrends) {
        this.$refs.performanceTrends.initialLoadData();
      }
      if (this.$refs.competitiveTrends) {
        this.$refs.competitiveTrends.initialLoadData();
      }
      if (this.$refs.metricContributions) {
        this.$refs.metricContributions.initialLoadData();
      }
      if (this.$refs.predictiveAnalytics) {
        this.$refs.predictiveAnalytics.initialLoadData();
      }
      if (this.$refs.npsRank){
        this.$refs.npsRank.initialLoadData();
      }
      if (this.$refs.moatScoreRank){
        this.$refs.moatScoreRank.initialLoadData();
      }
      if (this.$refs.wScoreRank){
        this.$refs.wScoreRank.initialLoadData();
      }
      if (this.$refs.competitiveRank){
        this.$refs.competitiveRank.loadData();
      }
      if (this.$refs.npsTrends){
        this.$refs.npsTrends.loadData();
      }
      if (this.$refs.moatComps){
        this.$refs.moatComps.loadData();
      }
      if (this.$refs.moneyballStick){
        this.$refs.moneyballStick.loadData();
      }
      
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },     
    getSurveyGroupsByClientIdCallback(response) {
      this.surveyGroupList = [];
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let surveyGroups = response.data.data;
        this.surveyGroupList =  SurveyGroupService.getSurveyGroupOptions(surveyGroups,"Y",this.saveClientChartObject.clientId);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },       
  },
  watch: {
    saveClientChartObject: function() {
      this.loadData();
    },
  }
};
</script>
