<template>
  <div id="dashboard-moneyball-stick" :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/predictive" @click="$parent.logViewDetailsEvent(title)">            
            Moneyball <br>
            Stick
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Moneyball Stick" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show(modalName)"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-moneyball-stick">
        <dashboard-card-dropdown label="Stick Type" :default-dropdown-option="'Select'" :read-only="readOnly" :options="stick_type_options" :default-value="stickType" @change="saveStickType"></dashboard-card-dropdown>
        <div class="dashboard-card-dropdown">        
          <div class="control-label">{{ byTypeName }}</div>
          <multiselect
            ref="stickByOptionSelect"
            :disabled="readOnly"
            v-model="values"
            :options="byTypeOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :max-height="250"
            select-label=""
            deselect-label=""
            selected-label=""
            placeholder="Select Checkboxes"
            label="name"
            :max="maximumStickByOptions"
            name="multi-stick-by-option"
            track-by="value"
            :preselect-first="false"
            @close="updateCard"
            @select="addStickByOption"
            @remove="removeStickByOption"
          >
            <template slot="maxElements">
              <span style="color: #e54d42">{{ maximumStickByOptionMessage }}</span><a href="#" @click="clearSelections">Click here to clear selections</a>
            </template>
            <template #selection="{ values, isOpen }">
              <span class="multiselect__single"
                    v-if="values.length >= 1"
                    v-show="!isOpen">{{ values.length }} selected <a v-if="values.length >= 3" href="#" @click="clearSelections">Clear</a></span>
            </template>            
            <template #option="props">
              <span class="checkbox-label"  @click.self="select(props.option)">
                <input name="multi-stick-by-option" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                {{ props.option.name }}
              </span>
            </template>
          </multiselect>
        </div>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <div :id="containerId" class="dashboard-card-body">
      <dashboard-card-skeleton v-if="!displayDashboard" />
      <moneyball-stick-chart ref="eChart"
            v-show="this.noData == false && this.displayDashboard == true" 
            :e-chart-mini-id="'moneyball-stick-mini'"
            :e-chart-id="'moneyball-stick'"
            :input-report-type-name="reportTypeName"
            :input-client-chart="this.entryClientChart"
            :modal-id="'profile-by-moneyball-stick-chart'"/>        
      <div v-if="this.noData == true && this.displayDashboard == true" class="no-data-for-chart-container" style="height: 250px;">
          <div v-show="this.noData == true" class="no-data-for-chart mx-auto">
            <div class="pb-2">Not enough data in your selection</div>
            <div class="pb-2">exists to build a digital profile.</div>
            <div class="pb-2">Try selecting a different period,</div>
            <div class="pb-2">organization or stick type.</div>
          </div>
      </div>
    </div>
    <dashboard-modal 
      :modal-id="dashboardId" 
      :chart="this.chart" 
      :client-chart="this.clientChart" 
      :modal-chart-options="options"
      :dashboard-card-report-page-type-name="reportTypeName" 
      :show-moneyball-stick-display="displayDashboard"
      :modal-title="title" 
       />    
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import ChartService from "../../services/chart.service";
import ClientService from "../../services/client.service";  
import ReportService from "../../services/report.service";  
import MoneyballStickChart from "../../views/echarts/MoneyballStickChart.vue";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardDropdown from "./DashboardCardDropdown";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";
import "echarts/lib/chart/heatmap";
import Multiselect from "vue-multiselect";

export default {
  name: "DashboardMoneyballStick",
  mixins: [DashboardMixins],
  components: { SaveChart,DashboardCardSkeleton,DashboardCardDropdown,Multiselect,MoneyballStickChart },
  data() {
    return {
      initialCompetitorsLoaded: false,
      title: "Moneyball Stick",
      modalName: "moneyball-stick-save-chart-confirmation",     
      stickBy: [],
      values: [],
      disableStickByWidget: false,
      stickType: "",
      noData: true,
      stick_type_options: [
        { value: "by-competitor", name: "By Competitor" },
        { value: "by-benchmark", name: "By Benchmark" },
        { value: "by-segment", name: "By Segment" },
        { value: "trends", name: "Trends" },
      ],
      by_competitor_options: [],
      by_benchmark_options: [],
      by_segment_options: [],
      trends_options: [],
      options: {},
    };
  },
  props: {
    entryClientChart: {
      type: Object,
      default: function () {
        return {};
      },
    },
    inputCompetitorOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    inputClientCharts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    inputClientTimePeriodOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    maximumStickByOptions: {
      type: Number,
      default: 3
    },    
  },
  computed: {
    byTypeName: function() {
        if (this.stickType == "by-competitor"){
            return "By Competitor";      
        } else if (this.stickType == "by-benchmark"){
            return "By Benchmark";      
        } else if (this.stickType == "by-segment"){
            return "By Segment";      
        } else if (this.stickType == "trends"){
            return "Trends";      
        }
        return "";
    },
    byTypeOptions: function() {
        if (this.stickType == "by-competitor"){
            return this.by_competitor_options;      
        } else if (this.stickType == "by-benchmark"){
            return this.by_benchmark_options;      
        } else if (this.stickType == "by-segment"){
            return this.by_segment_options;      
        } else if (this.stickType == "trends"){
            return this.trends_options;      
        }
        return [];
    },
    showCompetitorsDropdown: function(){
      return this.by_competitor_options.length > 0;
    },
    maximumStickByOptionMessage: function() {
      return "Maximum of " + this.maximumStickByOptions + " options. Clear your selections and chose again.";
    },        
  },
  mounted() {
    this.setChartDetails();
  },
  created() {},
  methods: {
    initialLoadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardMoneyballStick::initialLoadData this.autoGenerateChartRun: " + this.autoGenerateChartRun + "  client id: " + this.entryClientChart.clientId + "  time period id: " + this.timePeriodId);
      this.clearData();
      if (this.loadCompetitorData == true){
        let clientId = (typeof this.entryClientChart !== "undefined" && typeof this.entryClientChart.clientId !== "undefined") ? this.entryClientChart.clientId : this.$parent.$parent.clientId;
        await ClientCompetitorService.getCompetitors(clientId, this.timePeriodId, this.loadCompetitors, false);
        this.initialCompetitorsLoaded = true;
      }
      let saved_card_options = ClientService.getAllSavedChartsByChartType(this.inputClientCharts, 1);
      saved_card_options.sort(ReportService.compareValues("name", "asc"));
      this.loadSegments(saved_card_options);
      ChartService.getTopCompanies(process.env.VUE_APP_BASE_URL, this.loadBenchmarkCompanies);
      if (this.autoGenerateChartRun){
          this.processAutoGenerateChart();
      } else {
        this.setStickType();
        this.setStickBy();
      }
      this.setChartDetails();
      this.updateCard();
    },
    loadData: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardMoneyballStick::loadData autoGenerateChartRun: " + this.autoGenerateChartRun + "  client id: " + this.entryClientChart.clientId + "  time period id: " + this.timePeriodId);
      if (this.autoGenerateChartRun){
        this.initialCompetitorsLoaded = true;
        this.initialLoadData();
      } else {
        this.displayDashboard = true; 
      }
      this.setChartDetails();
    },
    addStickByOption(selectedOption) {
      selectedOption.checked = true;
    },  
    removeStickByOption(selectedOption) {
      selectedOption.checked = false;
      this.updateCard();
    },
    setValues(value){
      this.values = [];
      this.stickBy = [];
      if (typeof value === 'string' && value.includes(',')) {
        this.stickBy = value.split(",");
      } else {
        this.stickBy.push(value);
      }
      if (typeof value === 'string' && value.includes('|')) {
        this.stickBy = value.split("|");
      } else {
        this.stickBy.push(value);
      }
      for (var i = 0; i < this.stickBy.length; i++) {
        let found = this.byTypeOptions.find(x => x.value == this.stickBy[i]);
        if (typeof found != "undefined") {
          if (typeof this.byTypeOptions[i] != "undefined") {
            this.values.push(this.byTypeOptions[i]);
            this.byTypeOptions[i].checked = true;
          }
        } else {
          if (typeof this.byTypeOptions[i] != "undefined") {
            this.values.push(this.byTypeOptions[i]);
            this.byTypeOptions[i].checked = false;
          }
        }
      }
    },
    setStickBy() {
      let userSettingValue = "";
      let defaultDashboardUserSetting = this.$parent.defaultDashboardValues.find(x => x.userSettingTypeName == DashboardService.DASHBOARD_MONEYBALL_STICK_BY_TYPE_SETTING_TYPE);      
      if (typeof defaultDashboardUserSetting != "undefined") {
        userSettingValue = defaultDashboardUserSetting.userSettingValue;
        this.setValues(userSettingValue);
      }
    },
    setStickType() {
      //console.log("DashboardMoneyballStick::setStickType stickType " + this.stickType + " stick_type_options " + JSON.stringify(this.stick_type_options));
      this.stickType = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_MONEYBALL_STICK_STICK_TYPE_SETTING_TYPE, this.stickType, this.stick_type_options);
      let stickTypeFound = this.byTypeOptions.find(x => x.value == Number(this.stickType));
      if (typeof stickTypeFound == "undefined") {
        this.stickType = "by-competitor";
      } 
    },
    saveStickType: function (value) {
      this.stickType = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_MONEYBALL_STICK_BY_TYPE_SETTING_TYPE, this.stickType);
      this.clearSelections();
      
    },
    clearSelections() {
      for (var i = 0; i < this.values.length; i++) {
        this.values[i].checked = false;
      }
      this.stickBy = [];
      this.values = [];
      this.updateCard();
    },
    updateCard: function() {
      this.resetChart();
      if (this.initialCompetitorsLoaded == true){
        this.displayDashboard = false;
        let vm = this;
        setTimeout(function() {
          if (vm.timePeriodId != "" && vm.stickType != "") {
            vm.displayDashboard = false;
            vm.stickBy = vm.values.map(x => x.value); 
            this.disableStickByWidget = true;
            DashboardService.getMoneyballStick(vm.timePeriodId, vm.stickType, vm.stickBy, vm.getMoneyballStickCallback, vm);
          } else {
            vm.displayDashboard = true;
          }
        }, 1000);
      }
    },
    loadCompetitors(data) {
      this.by_competitor_options = [];
      for (var i = 0; i < data.length; i++) {
        this.by_competitor_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    loadSegments(data) {
      //console.log("DashboardMoneyballStick::loadBenchmarkCompanies data " + JSON.stringify(data));
      this.by_segment_options = [];
      for (var i = 0; i < data.length; i++) {
        this.by_segment_options.push({ value: data[i].clientChartId, name: data[i].name });
      }
    },
    loadBenchmarkCompanies(data) {
      //console.log("DashboardMoneyballStick::loadBenchmarkCompanies data " + JSON.stringify(data));
      this.by_benchmark_options = [];
      for (var i = 0; i < data.length; i++) {
        this.by_benchmark_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    loadClientTimePeriods(data) {
      //console.log("DashboardMoneyballStick::loadClientTimePeriods data " + JSON.stringify(data));
      this.trends_options = [];
      for (var i = 0; i < data.length; i++) {
        this.trends_options.push({ value: data[i].clientTimePeriodId, name: data[i].displayName });
      }
    },
    setChartDetails(){
      this.chartTitle = "Moneyball Stick";
      this.chartTypeName = "dashboard-moneyball-stick";
      this.containerId = "moneyball-stick-snap-id";
    	this.reportTypeName = "Dashboard - Moneyball Stick";
      this.modalName = "moneyball-stick-save-chart-confirmation";
    },
    clearData() {
      this.noData = true;
      this.displayDashboard = false;
      this.stickBy= [],
      this.values= [],
      this.stickType= "by-competitor";      
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isSurveyGroupId = criteriaParameters[0] == "stickType" ? true : false;
  		if (isSurveyGroupId){
        this.stickType = criteriaParameters[1];
  		}
      let isStickBy = criteriaParameters[0] == "stickBy" ? true : false;
  		if (isStickBy){
        let savedStickBy = "";
        try {
          savedStickBy = JSON.parse(criteriaParameters[1]);
        } catch (e) {
          savedStickBy = decodeURIComponent(criteriaParameters[1]).replace(/\+/g, " ");
        }  
        this.setValues(savedStickBy);
  		}      
    },    
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("stickType",this.stickType);
      params.append("stickBy",this.stickBy);      
      return params;
    },  
    getMoneyballStickCallback(response) {
      this.disableStickByWidget = false;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
        let seriesData = response.data.data;
        this.noData = seriesData.noData;
        if (this.noData == false && this.$refs.eChart){
          this.$refs.eChart.processChart(true,seriesData,true);
          this.options = this.$refs.eChart.options;
        }
        this.displayDashboard = true;
        if (this.autoGenerateChartRun && !this.readOnly){
          this.minDashboardChartHeight = 0;
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
  },
  watch: {
    timePeriodId: function() {
      if (this.timePeriodId != "") {
        this.initialLoadData();
      }
    },
    inputCompetitorOptions: function() {
      this.by_competitor_options = JSON.parse(JSON.stringify(this.inputCompetitorOptions)); 
      this.initialCompetitorsLoaded = true;
    },
    inputClientTimePeriodOptions: function() {
      let data = JSON.parse(JSON.stringify(this.inputClientTimePeriodOptions));      
      this.loadClientTimePeriods(data);
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>