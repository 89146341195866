import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import moment from "moment-timezone";
import $ from "jquery";

const API_CLIENT_TIME_PERIOD_ADMIN_URL = AdminService.getBaseUrl() + "/api/v1/client-time-period";

class ClientTimePeriodService {
  getTimePeriodName(listOfClientTimePeriods, clientTimePeriodId) {
    let periodName = "";
    for (let i = 0; i < listOfClientTimePeriods.length; i++) {
      if (listOfClientTimePeriods[i].clientTimePeriodId == Number(clientTimePeriodId)) {
        periodName = listOfClientTimePeriods[i].periodName;
        break;
      }
    }
    return periodName;
  }
  async importWaveFile(file, includeHierarchy, callback, chartApp) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("includeHierarchy", includeHierarchy);
    formData.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/import-wave-file", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.importWaveFile", error, "survey-designer", chartApp);
      });
  }  
  async deleteWaves(waveIds,callback,app){
    const params = new URLSearchParams();
    params.append("waveIds", waveIds);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/delete-waves", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.deleteWaves", error, "admin", app);
      });
  }
  async getWavesList(callback,app){
    const params = new URLSearchParams();
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/get-waves-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.getWavesList", error, "admin", app);
      });

  }

  async getClientTimePeriods(callback, chartApp) {
    let clientId = ClientService.getClientId();
    let userId = UserService.getUserId();
    if (clientId != 0 && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("userId", userId);
      await axios
        .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/get-client-time-periods", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientTimePeriodService.getClientTimePeriods", error, "survey-designer", chartApp);
        });
    }
  }
  async getClientTimePeriodsBySurveyCode(surveyCode, callback, chartApp) {
    let clientId = ClientService.getClientId();
    let userId = UserService.getUserId();
    if (clientId != 0 && typeof userId != "undefined" && surveyCode != "") {
      const params = new URLSearchParams();
      params.append("surveyCode", surveyCode);
      params.append("userId", userId);
      await axios
        .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/get-client-time-periods-by-survey-code", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientTimePeriodService.getClientTimePeriodsBySurveyCode", error, "survey-designer", chartApp);
        });
    }
  }
  async removeRespondentWave(index, clientCode, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("clientTimePeriodId", index);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/delete-respondent-waves", params, { headers: await authHeader() })
      .then(response => chartApp.respondentWaveCallback(response, index))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.removeRespondentWave", error, "admin", chartApp);
      });
  }
  async getAllRespondentWaves(clientCode, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/get-all-respondent-waves", params, { headers: await authHeader() })
      .then(response => chartApp.respondentWaveCallback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.getAllRespondentWaves", error, "admin", chartApp);
      });
  }
  async saveAllRespondentWaves(clientCode, respondentWaveListObject, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("respondentWaveListObject", JSON.stringify(respondentWaveListObject));
    await axios
      .post(API_CLIENT_TIME_PERIOD_ADMIN_URL + "/save-respondent-waves", params, { headers: await authHeader() })
      .then(response => chartApp.respondentWaveCallback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientTimePeriodService.saveAllRespondentWaves", error, "admin", chartApp);
      });
  }
  getPeriodName(period_options, value) {
    if (period_options.length > 0) {
      let period = period_options.find(x => x.clientTimePeriodId == value);
      if (typeof period != "undefined") {
        return period.displayName;
      }
    }
    return "";
  }
  getStartAndEndDateForAnnual(year) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};

    startDateString += year;
    startDateString += "-01-01";
    endDateString += year;
    endDateString += "-12-31";

    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateForSemiAnnual(year, period) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};

    if (period == "2") {
      startDateString += year;
      startDateString += "-07-01";
      endDateString += year;
      endDateString += "-12-31";
    } else {
      startDateString += year;
      startDateString += "-01-01";
      endDateString += year;
      endDateString += "-06-30";
    }
    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateForThree(year, period) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};
    if (period == "2") {
      startDateString += year;
      startDateString += "-05-01";
      endDateString += year;
      endDateString += "-08-31";
    } else if (period == "3") {
      startDateString += year;
      startDateString += "-09-01";
      endDateString += year;
      endDateString += "-12-31";
    } else {
      startDateString += year;
      startDateString += "-01-01";
      endDateString += year;
      endDateString += "-04-30";
    }
    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateForPeriod(year, period) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};

    if (period == "2") {
      startDateString += year;
      startDateString += "-04-01";
      endDateString += year;
      endDateString += "-06-30";
    } else if (period == "3") {
      startDateString += year;
      startDateString += "-07-01";
      endDateString += year;
      endDateString += "-09-30";
    } else if (period == "4") {
      startDateString += year;
      startDateString += "-10-01";
      endDateString += year;
      endDateString += "-12-31";
    } else {
      startDateString += year;
      startDateString += "-01-01";
      endDateString += year;
      endDateString += "-03-31";
    }

    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateForEveryTwoMonths(year, period) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};

    if (period == "2") {
      startDateString += year;
      startDateString += "-03-01";
      endDateString += year;
      endDateString += "-04-30";
    } else if (period == "3") {
      startDateString += year;
      startDateString += "-05-01";
      endDateString += year;
      endDateString += "-06-30";
    } else if (period == "4") {
      startDateString += year;
      startDateString += "-07-01";
      endDateString += year;
      endDateString += "-08-31";
    } else if (period == "5") {
      startDateString += year;
      startDateString += "-09-01";
      endDateString += year;
      endDateString += "-10-31";
    } else if (period == "6") {
      startDateString += year;
      startDateString += "-11-01";
      endDateString += year;
      endDateString += "-12-31";
    } else {
      startDateString += year;
      startDateString += "-01-01";
      endDateString += year;
      endDateString += "-02-28";
    }

    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateForMonthly(year, period) {
    let startDateString = "";
    let endDateString = "";
    let dates = {};

    if (period == "2") {
      startDateString += year;
      startDateString += "-02-01";
      endDateString += year;
      endDateString += "-02-28";
    } else if (period == "3") {
      startDateString += year;
      startDateString += "-03-01";
      endDateString += year;
      endDateString += "-03-31";
    } else if (period == "4") {
      startDateString += year;
      startDateString += "-04-01";
      endDateString += year;
      endDateString += "-04-30";
    } else if (period == "5") {
      startDateString += year;
      startDateString += "-05-01";
      endDateString += year;
      endDateString += "-05-31";
    } else if (period == "6") {
      startDateString += year;
      startDateString += "-06-01";
      endDateString += year;
      endDateString += "-06-30";
    } else if (period == "7") {
      startDateString += year;
      startDateString += "-07-01";
      endDateString += year;
      endDateString += "-07-31";
    } else if (period == "8") {
      startDateString += year;
      startDateString += "-08-01";
      endDateString += year;
      endDateString += "-08-31";
    } else if (period == "9") {
      startDateString += year;
      startDateString += "-09-01";
      endDateString += year;
      endDateString += "-09-30";
    } else if (period == "10") {
      startDateString += year;
      startDateString += "-11-01";
      endDateString += year;
      endDateString += "-11-31";
    } else if (period == "11") {
      startDateString += year;
      startDateString += "-11-01";
      endDateString += year;
      endDateString += "-11-30";
    } else if (period == "12") {
      startDateString += year;
      startDateString += "-12-01";
      endDateString += year;
      endDateString += "-12-31";
    } else {
      startDateString += year;
      startDateString += "-01-01";
      endDateString += year;
      endDateString += "-02-28";
    }

    dates.start_date = startDateString;
    dates.end_date = endDateString;
    return dates;
  }
  getStartAndEndDateInitializeDate(year, period, number_of_periods) {
    let dates = {};
    if (number_of_periods == 1) {
      dates = this.getStartAndEndDateForAnnual(year);
    } else if (number_of_periods == 2) {
      dates = this.getStartAndEndDateForSemiAnnual(year, period);
    } else if (number_of_periods == 3) {
      dates = this.getStartAndEndDateForThree(year, period);
    } else if (number_of_periods == 4) {
      dates = this.getStartAndEndDateForPeriod(year, period);
    } else if (number_of_periods == 6) {
      dates = this.getStartAndEndDateForEveryTwoMonths(year, period);
    } else if (number_of_periods == 12) {
      dates = this.getStartAndEndDateForMonthly(year, period);
    }
    let startDate = new Date(dates.start_date);
    let endDate = new Date(dates.end_date);

    dates.start_date = startDate.toISOString();
    dates.end_date = endDate.toISOString();

    return dates;
  }
  getPeriodOptions() {
    return [
      { name: "1", value: 1 },
      { name: "2", value: 2 },
      { name: "3", value: 3 },
      { name: "4", value: 4 },
      { name: "5", value: 5 },
      { name: "6", value: 6 },
      { name: "7", value: 7 },
      { name: "8", value: 8 },
      { name: "9", value: 9 },
      { name: "10", value: 10 },
      { name: "11", value: 11 },
      { name: "12", value: 12 }
    ];
  }
  getNumberOfPeriodOptions() {
    return [
      { name: "One (Annual)", value: 1 },
      { name: "Two (Biannual)", value: 2 },
      { name: "Three (Every 4 months)", value: 3 },
      { name: "Four (Quarterly)", value: 4 },
      { name: "Six (Every 2 months)", value: 6 },
      { name: "Twelve (Monthly)", value: 12 }
    ];
  }
  getAdminWavesTableColumnData() {
    let columnData = [];
    let baseData = this.getBaseWavesTableColumnData();
    columnData.push(this.getDeleteWaveCheckbox());
//      columnData.push(this.getViewButton());
    columnData.push({title: "Client Time Period Id",data: "clientTimePeriodId",className: "text-left",name: "id",width: "4rem"});
    columnData = columnData.concat(baseData);
    return columnData;
  }  
  getBaseWavesTableColumnData() {
    return [
      { title: "Client Id", data: "clientId",className: "text-left", name: "clientId", width: "3rem" },
      { title: "Client Name", data: "clientName",className: "text-left", name: "clientName", width: "7rem" },
      { title: "Year", data: "year", name: "year",className: "text-left", width: "3rem" },
      { title: "Period", data: "period", name: "period",className: "text-left", width: "3rem" },
      { title: "Period Name", data: "periodName",className: "text-left", name: "periodName", width: "5rem" },
      { title: "Display Name", data: "displayName",className: "text-left", name: "displayName", width: "5rem" },
      {
        title: "Start Date",
        data: "startDate",
        type: "date",
        name: "startDate",
        width: "3rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        title: "End Date",
        data: "endDate",
        type: "date",
        name: "endDate",
        width: "3rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        title: "Date Created",
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "3rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        title: "Last Modified",
        data: "dateModified",
        type: "date",
        name: "dateModified",
        width: "4rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
  }
  getDeleteWaveCheckbox(){
    return           {
      title: "",
      bVisible: true,
      data: "id",
      name: "id",
      width: "3rem",
      className: "select-checkbox text-center",
      render: function(data, type, full, meta) {
        return '<input type="checkbox" name="id[]" value="' + data.clientTimePeriodId + '">';
      }
    };
  }  
}

export default new ClientTimePeriodService();
