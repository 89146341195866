<template>
    <div class="dashboard-settings">
      <div class="dashboard-preview-container">
        <div class="dashboard-layout-header"><div class="text-center">Dashboard Preview</div></div>
        <div class="dashboard-card-container">
          <div
            v-for="(dashboardCard, idxDashboardCard) in dashboardLayoutCards"
            v-bind:key="idxDashboardCard"
            @change="enableSaveOptionsButton = true"
            :class="getClass(dashboardCard.dashboardCardTypeCode, dashboardCard.sequenceNumber, true)"
          ></div>
        </div>
      </div>
      <div class="dashboard-options-container">
        <div class="dashboard-layout-header">Display Options</div>
        <div class="text-center">
          Adjust how your dashboard appears when <br />
          you log into the wRatings system.
        </div>
        <div class="dashboard-display-options">
          <div class="mx-auto">
            <div v-for="(dashboardCard, idxDashboardCard) in dashboardLayoutCards" v-bind:key="idxDashboardCard" @change="enableSaveOptionsButton = true" class="form-group form-inline">
              <div class="pr-2">Position {{ idxDashboardCard + 1 }}</div>
              <div>
                <select :id="'dashboard_card_options'+idxDashboardCard" class="custom-select required w-100" v-model="dashboardCard.dashboardCardTypeCode">
                  <option value="">Leave Blank</option>
                  <option
                    v-for="(dashboard_card_type_option, idxDashboardCardTypeOptions) in dashboardCardTypeOptions"
                    v-bind:value="dashboard_card_type_option.code"
                    v-bind:key="idxDashboardCardTypeOptions"
                  >
                    <span>{{ dashboard_card_type_option.name }}</span>
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary float-right pt-2 mr-4" :disabled="!this.enableSaveOptionsButton" @click="this.saveOptions">
                <span>Save Options</span>
                <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import DashboardService from "../services/dashboard.service";
import AdminService from "../services/admin.service";
import ClientService from "../services/client.service";
import UserService from "../services/user.service";
import GoogleService from "../services/google.service";
import AlertService from "../services/alert.service";
import DashboardMixins from "../mixins/DashboardMixins.vue";

export default {
  name: "DashboardSettings",
  components: {},
  mixins: [
    DashboardMixins
  ],
  data() {
    return {
        dashboardLayoutCards: [],
        dashboardCardTypeOptions: [],
        enableSaveOptionsButton: false,
        defaultGroup: { dashboardCardId: 0, id: 0, sequenceNumber: 100, dashboardCardTypeCode: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.dashboardLayoutCards =  [],
      this.dashboardCardTypeOptions = [],

      this.$root.processServerRequest("Retrieving dashboard options");
      DashboardService.getDashboardCardTypes(this.getDashboardCardTypesCallback, this);
      DashboardService.getDashboardCards(this.initialUserId, this.getDashboardCardsCallback, this);
    },
    getDashboardCardTypesCallback: function (response) {
      this.dashboardCardTypeOptions = response.data;
    },
    getDashboardCardsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.dashboardLayoutCards = response.data.data;
        let currentSequenceNumber = this.dashboardLayoutCards.length;
        while (this.dashboardLayoutCards.length < 6) {
          let cardToAdd = JSON.parse(JSON.stringify(this.defaultGroup));
          currentSequenceNumber += 1;
          cardToAdd.sequenceNumber = currentSequenceNumber;
          this.dashboardLayoutCards.push(cardToAdd);
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveOptions: function () {
      let message = this.validateOptions();
      if (message == "") {
        this.$root.processServerRequest("Saving dashboard options");
        GoogleService.logClickEvent(ClientService.getClientName(), "Save Options", 0, this);
        DashboardService.saveDashboardCards(this.initialUserId,this.dashboardLayoutCards, this.getDashboardCardsCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      }
    },
    validateOptions: function () {
      let errorMessage = "";
      let selectedCodes = this.dashboardLayoutCards.map((a) => a.dashboardCardTypeCode);
      let duplicatesExist = selectedCodes.some((element, index) => {
        if (element == ""){
          return false;
        }
        return selectedCodes.indexOf(element) !== index;
      });
      if (duplicatesExist) {
        errorMessage = "You can only include one dashboard card per position.";
      }
      return errorMessage;
    },    
  },
  computed: {},
  props: {
    initialUserId: {
      type: Number,
      default: UserService.getUserId(),
    },    
  },
  watch: {
    initialUserId: function () {
      this.loadData();
    },
  },  
};
</script>
